<template>
  <div class="deposit bg-base mt-8 mb-9">
    <div class="deposit-top flex flex-col sm:flex-row mb-5 gap-4 sm:gap-0 sm:items-end">
      <div class="deposit-top__block flex flex-wrap gap-4 sm:gap-0">
        <div class="deposit-field select-field w-full sm:w-auto">
          <div class="lable">{{ $t('general.crypto') }}</div>
          <AppSelect class="left" :i18n="false" :lable="depositCrypto" :options="['All']" @select="selectCrypto" />
        </div>
        <div class="deposit-field select-field w-full sm:w-auto sm:ml-4">
          <div class="lable">{{ $t('general.direction') }}</div>
          <AppSelect class="left" :i18n="false" :lable="depositDirection" :options="['All']" @select="selectDirection" />
        </div>
        <div class="deposit-field select-field sm:ml-4 w-full sm:w-auto">
          <div class="lable">{{ $t('time.time') }}</div>
          <AppSelect class="left  w-full sm:w-auto" :i18n="false" style="width: 210px" :lable="depositTimeStart"
            :lableTwo="depositTimeEnd" :options="['All']" @select="selectTime" />
        </div>
      </div>
      <div class="deposit-top__btn flex sm:ml-auto">
        <button class="btn px-7 max-h-8">{{ $t('general.reset') }}</button>
        <button class="btn btn-yellow px-7 ml-4 max-h-8">{{ $t('general.search') }}</button>
      </div>
    </div>
    <div class="deposit-table">
      <table class="table">
        <thead>
          <tr>
            <th v-for="header in tableHeader" :key="header">
              {{ $t(header) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <td :colspan="tableHeader.length">
            <div class="table-empty">
              <div class="able-empty__img w-28">
                <img src="img/folder.svg" alt="">
              </div>
              <span>{{ $t('general.no_data') }}</span>
            </div>
          </td>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import AppSelect from '@/components/ui/AppSelect'

export default {
  name: 'DepositHistory',
  data: () => ({
    tableHeader: ['time.time', 'general.crypto', 'deposit_history.types', 'general.amount', 'deposit_history.convert_address', 'general.status'],
    depositCrypto: 'All',
    depositDirection: 'All',
    depositTimeStart: '2022-04-01',
    depositTimeEnd: '2022-04-12'
  }),
  components: {
    AppSelect
  },
  methods: {
    selectCrypto (option) {},
    selectDirection (option) {},
    selectTime (option) {}
  }
}
</script>
